<!-- 文件中文名: 推荐网络图(树形) -->
<template>
  <div class="network-pic app-container">
    <div class="page-container account">
      <div class="filter">
        <el-form :inline="true" :model="listQuery" size="mini">
          <!--经销商编号-->
          <el-form-item :label="$t('miMemberNetwork.memberNo')+':'">
            <el-input @input="value=>listQuery.memberNo=value.toUpperCase()" v-model.trim="listQuery.memberNo" size="mini"/>
          </el-form-item>
          <!--搜索-->
          <el-button :loading="btnLoading" icon="el-icon-search" plain type="primary" size="mini" @click="handleFilter">
            {{ $t('operation.button.search') }}
          </el-button>
        </el-form>
        <table v-show="!btnLoading" class="view-table">
          <thead>
           <tr style="background-color: rgba(248,133,154,0.4)">
              <!-- 一般情况下每月5号更新上月荣衔 -->
             <th>{{ $t('commonly.No5.to_update') }}</th>
           </tr>
          </thead>
          <tbody>
          <tr>
            <!-- 大区总业绩 -->
            <th>{{ $t('Region.Total_performance') }} ({{ cardInfoObj.partNo }})</th>
          </tr>
          <tr>
            <td>{{ cardInfoObj.netsell }}</td>
          </tr>
          <tr>
            <!-- 其他区总业绩 -->
            <th>{{ $t('Other_areas.Total_performance') }}</th>
          </tr>
          <tr>
            <td>{{ cardInfoObj.netsellqita }}</td>
          </tr>
          <tr>
            <!-- 所能达到的荣衔 -->
            <th>{{ $t('Can_reach.cardtype') }}</th>
          </tr>
          <tr>
            <td>{{ $lt(initDict.cardType, cardInfoObj.nextCardType) }}</td>
          </tr>
          </tbody>
        </table>
        <p v-if="btnLoading" style="font-size: 2.5rem; color: #e9792e; font-weight: 700">
          <i class="el-icon-loading"/>
          {{ this.$t('query_data_wait') }}
        </p>
      </div>
      <table v-show="this.viewData" class="div_in_table_demo"  style="margin: 20px">
        <tbody>
        <tr>
          <th v-if="language==='zh_CN'" rowspan="4">经销商编号</th>
          <th v-else rowspan="4">Numero de distribuidor</th>
        </tr>
        <tr>
          <!-- 团队人数-->
          <th v-if="language==='zh_CN'" style="color: #000">团队人数</th>
          <th v-else style="color: #000">Personas</th>
          <!-- 团队: 上月首购-->
          <th v-if="language==='zh_CN'" style="color: #69cf91">团队: 上月首购</th>
          <th v-else style="color: #69cf91"> Equipo:Tarjeta mes anterior</th>
          <!-- 团队: 上月重消-->
          <th v-if="language==='zh_CN'" style="color: #ff3052">团队: 上月重消</th>
          <th v-else style="color: #ff3052">Equipo:Recompra mes anterior</th>
          <!-- 团队: 目前首购-->
          <th v-if="language==='zh_CN'" style="color: #ff4e38">团队: 目前首购</th>
          <th v-else style="color: #ff4e38">Equipo:Tarjeta actual</th>
          <!-- 团队: 目前重消-->
          <th v-if="language==='zh_CN'" style="color: #263efe">团队: 目前重消</th>
          <th v-else style="color: #263efe">Equipo:Recompra actual</th>
        </tr>
        <tr style="margin-top: 10px">
          <!-- 累计业绩(首购)-->
          <th v-if="language==='zh_CN'" style="color: #000">累计业绩(首购)</th>
          <th v-else style="color: #000"> Pv acumulado de tarjeta</th>
          <!-- 个人: 上月首购-->
          <th v-if="language==='zh_CN'" style="color: #5c5924">个人: 上月首购</th>
          <th v-else style="color: #5c5924">Personal:Tarjeta mes anterior</th>
          <!-- 个人: 上月重消-->
          <th v-if="language==='zh_CN'" style="color: #a6345d">个人: 上月重消</th>
          <th v-else style="color: #a6345d">Personal:Recompra mes anterior</th>
          <!-- 个人: 目前首购-->
          <th v-if="language==='zh_CN'" style="color: #692f3c">个人: 目前首购</th>
          <th v-else style="color: #692f3c">Personal:Tarjeta actual </th>
          <!-- 个人: 目前重消-->
          <th v-if="language==='zh_CN'" style="color: #454576">个人: 目前重消</th>
          <th v-else style="color: #454576">Personal:Recompra actual</th>
        </tr>
        </tbody>
      </table>
      <div class="mytree" style="width: 100%;height: 600px;overflow: auto">
        <el-tree
            empty-text=""
            v-if="treeIf"
            node-key="memberNo"
            lazy
            :props="props"
            :load="loadNode"
        >
          <template slot-scope="scope">
            <div :class="colorOne[scope.data.levelType ? (scope.data.levelType).substr(0,1) : '']" style="color: red; border: 2px #e396cd solid; width: 580px; border-radius: 7px;">
              <div style="display: flex; justify-content: space-between">
                <p
                    v-if="scope.data.recommendLayer>0"
                    style="font-size: 16px;color: #000;font-weight: 600;margin-left: 5px">
                  {{scope.data.recommendLayer}}
                </p>
                <p
                    style="margin-left: 15px; flex: 1; font-weight: 700;text-decoration: underline;cursor: pointer;"
                    @click="getClickData(scope.data.memberNo)">
                  {{ scope.data.memberNo }}
                </p>
                <div style="margin-right: 15px; flex: 2">
                  <table class="div_in_table">
                    <tbody>
                    <tr>
                      <!-- 团队人数-->
                      <th style="color: #000">{{ scope.data.recommendNum }}</th>
                      <!-- 团队: 上月首购-->
                      <th style="color: #69cf91">{{ scope.data.reNetSgsell }}</th>
                      <!-- 团队: 上月重消-->
                      <th style="color: #ff3052">{{ scope.data.reNetConsell }}</th>
                      <!-- 团队: 目前首购-->
                      <th style="color: #ff4e38">{{ scope.data.bqRenetPersell }}</th>
                      <!-- 团队: 目前重消-->
                      <th style="color: #263efe">{{ scope.data.bqRenetConsumsell }}</th>
                    </tr>
                    <tr style="margin-top: 10px">
                      <!-- 累计业绩(首购)-->
                      <th style="color: #000">{{ scope.data.totalNetsgsell }}</th>
                      <!-- 个人: 上月首购-->
                      <th style="color: #5c5924">{{ scope.data.perSell }}</th>
                      <!-- 个人: 上月重消-->
                      <th style="color: #a6345d">{{ scope.data.consumSell }}</th>
                      <!-- 个人: 目前首购-->
                      <th style="color: #692f3c">{{ scope.data.bqPersell }}</th>
                      <!-- 个人: 目前重消-->
                      <th style="color: #454576">{{ scope.data.bqConsumsell }}</th>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </template>
        </el-tree>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex"
  import 'vue-organization-chart/dist/orgchart.css'
  import { getPositionViews2, getCardType } from "@/api/rus/api"
  export default {
    name: 'NetworkTree',
    data() {
      return {
        cardInfoObj: {},
        treeIf: true,
        btnLoading: false,
        colorOne:['levelType0','levelType1','levelType2','levelType3','levelType4'],
        color:['levelType4','levelType3','levelType2','levelType1', 'levelType0'],
        viewData: {},
        props: {
          label: 'label',
          children: 'children',
          isLeaf: 'leaf'
        },
        listQuery: {
          upOrDown: 'down',
          memberNo: '',
          treeOrView: 'Tree',
          Layer: 0,
        },
        initDict: {
          levelType:'ru.member.level',
          cardType: 'ru.mimember.cardtype'
        }
      }
    },
    computed: {
      ...mapGetters([
        'userCode',
        'language'
      ])
    },
    created() {
      this.listQuery.memberNo = this.userCode
    },
    mounted() {
      this.getCardInfo()
    },
    methods: {
      async loadNode(node, resolve) {
        if (node.level === 0) {
          const a = await getPositionViews2({...this.listQuery}).then(res => { return res.data.data }).catch(err => {
            console.error(err)
          })
          return resolve([a])
        }
        if (node.level >= 1) {
          this.listQuery.memberNo = node.key
          this.listQuery.Layer = 1
          const a = await getPositionViews2({...this.listQuery}).then(res => {
            res.data.data.children.forEach(v => {
              v.recommendLayer = node.level
            })
            return res.data.data
          }).catch(err => {
            console.error(err)
          })
          return resolve(a.children)
        }
      },
      handleFilter() {
        if (!this.listQuery.memberNo) {
          return false
        }
        this.btnLoading = true
        this.treeIf = false
        getPositionViews2({...this.listQuery}).then(res => {
          this.viewData = res.data.data
          this.btnLoading = false
          this.treeIf = true
          this.getCardInfo()
        }).catch(err => {
          this.btnLoading = false
          this.treeIf = false
          console.warn(err)
        })
      },
      getCardInfo() {
         getCardType({ 'memberNo': this.listQuery.memberNo }).then(res => {
          this.cardInfoObj = res.data
        }).catch(() => {})
      },
      // eslint-disable-next-line no-unused-vars
      async getClickData(memberNo) {
        this.btnLoading = true
        this.treeIf = false
        await getPositionViews2({...this.listQuery, memberNo: memberNo}).then(res => {
          this.viewData = res.data.data
          this.btnLoading = false
          this.treeIf = true
        }).catch(err => {
          this.btnLoading = false
          this.treeIf = false
          console.warn(err)
        })
      }
    }
  }
</script>
<style scoped lang="scss">
  .view-table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
    border: 1px solid #9a9a9a;
    th {
      border: 1px solid #9a9a9a;
    }
    td {
      border: 1px solid #9a9a9a;
    }
  }

  ::v-deep .mytree {
    .el-tree > .el-tree-node:after {
      border-top: none;
    }

    .el-tree-node {
      position: relative;
      padding-left: 16px;
    }
    .el-tree-node__content {
      height: 70px;
    }

    .el-tree-node__expand-icon.is-leaf {
      display: none;
    }

    .el-tree-node__children {
      padding-left: 16px;
    }

    .el-tree-node :last-child:before {
      height: 38px;
    }

    .el-tree > .el-tree-node:before {
      border-left: none;
    }

    .el-tree > .el-tree-node:after {
      border-top: none;
    }

    .el-tree-node:before {
      content: "";
      left: -4px;
      position: absolute;
      right: auto;
      border-width: 1px;
    }

    .el-tree-node:after {
      content: "";
      left: -4px;
      position: absolute;
      right: auto;
      border-width: 1px;
    }

    .el-tree-node:before {
      border-left: 2px solid #9b9b9b;
      bottom: 0;
      height: 100%;
      top: 0;
      width: 1px;
    }

    .el-tree-node:after {
      border-top: 2px solid #9b9b9b;
      height: 20px;
      top: 37px;
      width: 24px;
    }

    .el-tree>.el-tree-node {
      display: inline-block;
      min-width: 100%;
    }
  }
  .div_in_table {
    th {
      width: 100px;
    }
  }
  .div_in_table_demo {
    border: 2px solid #9b9b9b;
    padding: 5px;
    border-radius:10px ;
    bottom: 0;
    height: 100%;
    top: 0;
    th {
      width: 100px;
    }
  }
</style>

<style scoped>
  .levelType0{
    background-image: linear-gradient(#ffffff, #f7f7f7);
  }
  .levelType1{
    background-image: linear-gradient(#cdcdcd, #eaedf1);
  }
  .levelType2{
    background-image: linear-gradient(#50d2e7,#4dd5ed);
  }
  .levelType3{
    background-image: linear-gradient(#f1dc99,#e1c063);
  }
  .levelType4{
    background-image: linear-gradient(#f4748f,#ffa8af);
  }
</style>
